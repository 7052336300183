import React from "react"
import styled from "styled-components"

const StyledCloseButton = styled.button`
    position: absolute;
    top: 16px;
    right: 16px;
    height: 29px;
    width: 29px;
    background: none;
    border: none;
`

const Rectangle = styled.div`
    right: -2px;
    position: absolute;
    width: 30px;
    height: 1.5px;
    background: #000;
    transform: ${p => `rotate(${p.rotate}deg)`};
`


const CloseButton = ({ onClick }) => {

  return (
    <StyledCloseButton onClick={onClick}>
      <Rectangle rotate={45} />
      <Rectangle rotate={-45} />
    </StyledCloseButton>
  )
}

export default CloseButton
