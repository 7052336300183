import React from "react"
import styled from "styled-components"


const StyledMenuMobile = styled.div`
    width: 100%;
    height: 40px;
    background-color: white;
    border-top: 1px solid black;
    bottom: ${p => p.bottom}px;
    position: fixed;
    z-index: 999999;
    display: flex;

    button {
        display: flex;
        flex: 1;
        background: none;
        border: none;
        padding: 0;
        
        > * {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            text-transform: ${p => p.uppercase ? 'uppercase' : 'initial'};
            color: black;
        }
    }
    button:nth-child(odd) {
        border-right: 1px solid #000;
    }
`


const MenuMobile = ({ children, bottom = 0, uppercase }) => {

  return (
    <StyledMenuMobile bottom={bottom} uppercase={uppercase}>
      {children}
    </StyledMenuMobile>
  )
}




export default MenuMobile
