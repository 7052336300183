import React from "react"
import styled from "styled-components"

const StyledLabel = styled.div`
    background-color: #000;
    color: white;
    border-radius: 50%;
    display: inline-block;
    font-size: 14px;

    > span {
        height: 25px;
        width: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
`

const Label = ({ value }) => {
    const _value = String(value)?.length === 1 ? `0${value}` : value

    return (
        <StyledLabel>
            <span>
                {_value}
            </span>
        </StyledLabel>
    )
}

export default Label
