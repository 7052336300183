import React, { useCallback, useState, useEffect } from "react";
import { useMediaQuery } from 'react-responsive';
import { useStaticQuery, graphql } from "gatsby";
import { Helmet } from "react-helmet"
import HomeComponent from "../wrapper_components/HomeComponent";
import NewsBar from "../wrapper_components/NewsBar";
import NewsBody from "../wrapper_components/NewsBody";
import NewsBodyMobile from "../wrapper_components/NewsBodyMobile";
import AboutComponent from "../wrapper_components/AboutComponent";
import styled from "styled-components";
import Tag from '../components/Tag';
import MenuButton from '../components/MenuButton'
import MenuMobile from "../components/MenuMobile"
import TloLogo from "../components/TloLogo";
import "../globalStyles/normalize.css";
import "../globalStyles/styles.css";
import useWindowDimensions from '../utils/useWindowDimensions'

const mobileScreen = 800


const Ball = styled.div`
    position: fixed;
    mix-blend-mode: difference;
    top: ${p => p.top}px;
    left: ${p => p.left}px;
    pointer-events: none;
    z-index: 9999999999;
    height: 0;
    width: 0;
    transition: transform 0.2s ease;
    transform:  ${p => p.linkHovered ? 'translate(-50%,-50%) scale(2)' : 'translate(-50%,-50%) scale(1)'};
`

const Wrapper = styled.div`
  .desktop {
    display: initial;
  }
  .mobile {
    display: none;
  }

  @media (max-width: ${mobileScreen}px) {
    .desktop {
      display: none;
    }
    .mobile {
      display: initial;
    }
  }
`

const Button = styled.button`
  z-index: 9998;
  position: absolute;
  text-align: center;
  text-transform: uppercase;
  color: white; 
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  outline: inherit;
  margin-top: ${(p) => (p.inAbout ? '30px' : '20px')};
  mix-blend-mode: ${(p) => (p.inAbout ? 'initial' : 'difference')};
  left: ${(p) => (p.inAbout ? 'initial' : '50%')};
  cursor: ${(p) => (p.inAbout ? 'pointer' : 'none')};
  width: ${(p) => (p.inAbout ? 'initial' : '68px')};
  margin-left: ${(p) => (p.inAbout ? 'initial' : '-34px')};
  right: ${(p) => (p.inAbout ? '42px' : 'initial')};

  h3 {
    margin: 2px;
  }

  @media (max-width: 1200px) {
    width: 48px;
    margin-left: -24px; 
  }
`

const NewsMenu = styled.div`
    background-color: white;
    position: fixed;
    width: 100%;
    padding: 20px 0 20px;
    border-top: 1px solid #000;
    z-index: 99999;
    transition: all 0.3s ease-in-out;
    bottom: ${p => p.isOpen ? 40 : -(44 * p.items) - 84}px;
    max-height: ${p => (p.windowHeight * 0.7) - ((p.windowHeight * 0.7) % 44) + 24}px;
    overflow-y: scroll;
    
    button {
        > span {
            margin-left: 10px;
            color: black;
        }
        text-align: left;
        padding: 0 0 0 19px;
        flex: 1;
        background: none;
        border: none;
        height: 44px;
        width: 100%;
        border-bottom: 1px solid #000;
        display: flex;
        align-items: center;
    }
    button:last-child {
        border-bottom: none;
    }

`

const Layout = () => {
    const data = useStaticQuery(graphql`
    query Query {
        contentfulTloAboutPage {
            licenseNumber
        }
        allContentfulTloNews(sort: {date: DESC}) {
          edges {
            node {
                id
                createdAt
                date
                title
                body {
                  raw
                }
                image {
                  gatsbyImageData
                }
              }
          }
        }
      }
  `);

    const isMobile = useMediaQuery({ query: `(max-width: ${mobileScreen}px)` })
    const licenseNumber = data.contentfulTloAboutPage.licenseNumber
    const news = data.allContentfulTloNews.edges.map(elem => {
        elem.node.newsDate = elem.node.date || elem.node.createdAt
        return elem.node
    })
        .map(({ date, createdAt, ...rest }) => rest)
        .filter((elem) => (new Date(elem.newsDate) < new Date()))

    const newsSorted = news.sort((a, b) => (new Date(a.newsDate) - new Date(b.newsDate))).map((elem, index) => ({ ...elem, number: index + 1 }))

    const [newsOpen, setNewsOpen] = useState(newsSorted.map(() => false))
    const [zIndexes, setZIndexes] = useState(newsSorted.map((_, index) => index))
    const [newsMenuOpen, setNewsMenuOpen] = useState(false)
    const [aboutComponent, setAboutComponent] = useState(null)
    const [aboutOpen, setAboutOpen] = useState(false)
    const { height } = useWindowDimensions()

    const measuredRef = useCallback((node) => {
        if (node !== null) {
            setAboutComponent(node);
        }
    }, []);

    const handleNewsOpen = (newsOpen, news) => newsOpen.map((elem, index) => (news.number - 1 === index) ? true : elem)

    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [linkHovered, setLinkHovered] = useState(false);

    useEffect(() => {
        addEventListeners();
        handleLinkHoverEvents();
        handleClickEvent();
        return () => removeEventListeners();
    }, [position]);

    const addEventListeners = () => {
        document.addEventListener("mousemove", onMouseMove);
    };

    const removeEventListeners = () => {
        document.removeEventListener("mousemove", onMouseMove);
    };

    const onMouseMove = (e) => {
        setPosition({ x: e.clientX, y: e.clientY });
    };

    const handleLinkHoverEvents = () => {
        document.querySelectorAll("a").forEach(el => {
            el.addEventListener("mouseenter", () => setLinkHovered(true));
            el.addEventListener("mouseleave", () => setLinkHovered(false));
        });
        document.querySelectorAll("button").forEach(el => {
            el.addEventListener("mouseenter", () => setLinkHovered(true));
            el.addEventListener("mouseleave", () => setLinkHovered(false));
        });
    };

    const handleClickEvent = () => {
        document.addEventListener("click", () => setLinkHovered(false));
    };

    return (
        <Wrapper>
            <Helmet>
                <title>TŁO Michał Sikorski architekt</title>
            </Helmet>
            {!aboutOpen && <TloLogo />}

            <div className="desktop">
                <Ball
                    linkHovered={linkHovered}
                    left={position.x - (linkHovered ? 30 : 15)}
                    top={position.y - (linkHovered ? 30 : 15)}
                >
                    <svg height="30" width="30">
                        <circle cx="15" cy="15" r="12" strokeWidth="0" style={{ fill: 'white' }}></circle>
                    </svg>
                </Ball>
                <Button
                    onClick={() => aboutComponent.scrollIntoView({ behavior: 'smooth' })}>
                    <h3> About </h3>
                </Button>
                <Tag>
                    <a className='instagramLinkDesktop' href="https://www.instagram.com/tlo_archi" title="Odwiedź moje konto na instagramie" rel="noopener noreferrer" target="_blank">Instagram</a>
                </Tag>
                <Tag right={true} >
                    ©{new Date().getFullYear()}—{licenseNumber}
                </Tag>

                {newsSorted.filter((_, index) => newsOpen[index] === true).map(news => {
                    return <NewsBody
                        key={news.id}
                        open={false}
                        setNewsOpen={setNewsOpen}
                        newsOpen={newsOpen}
                        news={news}
                        zIndexes={zIndexes}
                        setZIndexes={setZIndexes}
                        handleNewsOpen={handleNewsOpen}
                    />
                })}
                <NewsBar
                    newsSorted={newsSorted.reverse()}
                    newsOpen={newsOpen}
                    setNewsOpen={setNewsOpen}
                    zIndexes={zIndexes}
                    setZIndexes={setZIndexes}
                    handleNewsOpen={handleNewsOpen}
                />
            </div>

            <div className="mobile">
                <NewsBodyMobile
                    open={newsOpen.some(elem => elem === true)}
                    newsOpen={newsOpen}
                    setNewsOpen={setNewsOpen}
                    news={newsSorted.filter((_, index) => newsOpen[index] === true)[0]}
                    setNewsMenuOpen={setNewsMenuOpen}
                />

                <MenuMobile bottom={newsOpen.some(elem => elem === true) ? 40 : 0}>
                    <button onClick={() => newsOpen[newsOpen.length - 1] !== true && setNewsOpen(newsOpen.map((_, index) => (newsOpen[index - 1] === true) ? true : false))} >
                        <h3> Previous  </h3>
                    </button>
                    <button onClick={() => newsOpen[0] !== true && setNewsOpen(newsOpen.map((_, index) => (newsOpen[index + 1] === true) ? true : false))} >
                        <h3> Next </h3>
                    </button>
                </MenuMobile>
                <NewsMenu
                    isOpen={!newsOpen.some(elem => elem === true) && newsMenuOpen}
                    items={news.length}
                    windowHeight={height}
                >
                    {newsSorted.map(news => <MenuButton
                        key={news.id}
                        onClick={() => setNewsOpen(newsOpen.map((_, index) => (Math.abs(news.number - newsSorted.length) === index) ? true : false))}
                        value={news.number}
                        text={news.title}
                    />)}
                </NewsMenu>
                <MenuMobile bottom={0} uppercase>
                    <button onClick={() => {
                        setNewsMenuOpen(!newsMenuOpen)
                        setNewsOpen(newsOpen.map(() => false))
                        setAboutOpen(false)
                    }}>
                        <h3> News </h3>
                    </button>
                    <button onClick={() => {
                        setAboutOpen(!aboutOpen)
                        setNewsMenuOpen(false)
                        setNewsOpen(newsOpen.map(() => false))
                    }}> <h3> About </h3> </button>
                </MenuMobile>
            </div>


            <HomeComponent
                licenseNumber={licenseNumber}
                active={!aboutOpen}
                isMobile={isMobile}
                visible={(isMobile || !newsOpen.some(elem => elem === true))}
            />

            {
                (!isMobile || aboutOpen) && <AboutComponent
                    innerRef={measuredRef}
                />
            }

        </Wrapper >
    )
}

export default Layout

