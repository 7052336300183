import React from "react";
import styled from "styled-components";

const StyledTloLogo = styled.svg`
  position: ${p => p.fixed ? 'fixed' : 'absolute' };
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  mix-blend-mode:   ${(p) => (p.fixed ? 'normal' : 'difference')};
  z-index: ${(p) => (p.fixed ? 0 : 99999)};

  path {
    fill: ${(p) => (p.fixed ? "#C8C8C8" : "white")};
  }
`;

const TloLogo = ({ fixed }) => {

  return (
    <StyledTloLogo
      width="354.33"
      height="146.903"
      viewBox="0 0 354.33 146.903"
      fixed={fixed}
    >
      <path
        d="M280.37799072 0c-41.28503418 0-74.15301513 32.86801147-74.15301513 73.35101318 0 40.68399048 32.86798095 73.55200196 74.15301513 73.55200196 41.08496094 0 73.95196533-32.86801148 73.95196533-73.55200196C354.32995605 32.86801148 321.46295166 0 280.37799072 0zM33.66900635 32.06600952v111.2290039H68.7409668V32.06600953h32.46704101V3.00601196H0v29.05999756h33.66900635zm84.3739624 111.2290039h79.56402588v-29.05999755h-44.4920044V74.75402832l40.28399659-11.02301025V33.66900635l-40.28399659 10.82202148V3.00601196h-35.07202148v51.10501099l-28.45898438 7.61599731v30.66299439l28.45898438-7.81600952v58.7210083zm205.22302246-69.94400024c0 25.05200196-16.83398437 44.49197388-42.88800049 44.49197388-26.05401611 0-43.08898926-19.43997192-43.08898926-44.49197388 0-25.05200195 17.03497315-44.29101562 43.08898926-44.29101562 26.05401612 0 42.8880005 19.23901367 42.8880005 44.29101562z"
      />
    </StyledTloLogo>
  );
};

export default TloLogo;
