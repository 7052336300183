import React from "react";
import styled from "styled-components";

const StyledTag = styled.div`
  z-index: 999999;
  position: absolute;
  top: calc(50% - 32px);
  right: ${p => p.right ? 'auto' : '50%'};
  left: ${p => p.right ? '50%' : 'auto'};
  text-transform: uppercase;
  text-align: center;
  mix-blend-mode: difference;
  color: white; 
`

const Transform = styled.div`
  width: calc(100vw - 72px);
  transform:  ${p => p.right ? 'rotate(-90deg)' : 'rotate(90deg)'};
  text-align: center;
  text-wrap: nowrap;
`


const Tag = ({ children, right }) => {
  return <StyledTag right={right}>
    <Transform right={right} >
      <h3>{children} </h3>
    </Transform>
  </StyledTag>
};

export default Tag;
