import React from "react"
import styled from "styled-components"
import Label from "./NewsLabelNumber"

const StyledTitle = styled.div`
    margin-right: 20px;
    font-family: Space Mono;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    display: flex;
    align-items: baseline;
    gap: 10px;
    background: none;
    border: none;
    padding: 0;
`



const Title = ({ as, number, text, onClick }) => {

    return (
        <StyledTitle onClick={onClick} as={as} >
            <Label value={number} />
            <span> {text} </span>
        </StyledTitle>
    )
}

export default Title
