import React, { useState, useEffect, useCallback } from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import styled from "styled-components";

const isBrowser = () => typeof window !== "undefined";

const Section = styled.section`
  overflow: hidden;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

const Project = styled.div`
  opacity: ${(props) => props.opacity};
  top: 0;
  position: absolute;
  width: 100%;
  height: 100%;
`;

const StyledMobileImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const ProjectTitle = styled.div`
  mix-blend-mode: difference;
  color: white;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-perspective: 1000px;
  perspective: 1000px;
  -webkit-backface-visibility: visible;
  backface-visibility: visible;

  font-size: 15px;
  position: absolute;
  z-index: 9999;
  bottom: 50px;
  width: 100%;
  text-align: center;
  margin-bottom: 2px;

  span {
    padding: 2px 6px;
  }
`;

const filteredImages = (project) => project.node.mobileImagesContainer?.filter(img => img?.showOnHomePage)

const MainWrapperMobile = ({ projects }) => {
  const [touched, setTouched] = useState(false);
  const [imageCountMobile, setImageCountMobile] = useState(0);
  const [chosenImageMobile, setChosenImageMobile] = useState(0);

  const filteredProjects = projects.filter(project => project.node.mobileImagesContainer)

  const getIndexesForEachNestedImageMobile = useCallback(() => {
    const indexes = [];
    filteredProjects.map((project) => {
      return filteredImages(project)?.forEach(() =>
        indexes.push(indexes.length + 1)
      );
    });
    return filteredProjects.map((project) => {
      return filteredImages(project)?.map(() => {
        return indexes.shift();
      });
    });
  }, [filteredProjects]);

  useEffect(() => {
    setImageCountMobile(getIndexesForEachNestedImageMobile().flat().length);
  }, [getIndexesForEachNestedImageMobile]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!touched) {
        setChosenImageMobile((chosenImageMobile) => {
          if (chosenImageMobile === imageCountMobile) {
            return 1;
          } else {
            return chosenImageMobile + 1;
          }
        })
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [touched, chosenImageMobile, imageCountMobile]);


  useEffect(() => {
    const onTouch = () => setTouched(true)
    isBrowser() && window.addEventListener('touchstart', onTouch);
    return () => {
      isBrowser() && window.removeEventListener('touchstart', onTouch);
    }
  }, []);

  useEffect(() => {
    const onTouch = () => setTouched(false)
    isBrowser() && window.addEventListener('touchend', onTouch);
    return () => {
      isBrowser() && window.removeEventListener('touchend', onTouch);
    }
  }, []);


  return (
    <>
      {filteredProjects.map((project, projectIndex) => {
        return (
          <Section key={project.node.title}>
            {filteredImages(project)?.map((image, imageIndex) => {
              const imageNumber =
                getIndexesForEachNestedImageMobile()[projectIndex][
                imageIndex
                ];
              return (
                <Project
                  key={imageIndex}
                  opacity={imageNumber === chosenImageMobile ? 1.0 : 0.0}
                >
                  <ProjectTitle>
                    <span>
                      {project.node.title} — {project.node.location}
                    </span>
                  </ProjectTitle>
                  <StyledMobileImage
                    src={getImage(image.image).images.fallback.src}
                    alt={project.node.title}
                  />
                </Project>
              );
            })}
          </Section>
        );
      })}
    </>
  );
};

export default MainWrapperMobile;
