import React from "react"
import styled from "styled-components"
import Title from "./NewsTitle";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { INLINES } from '@contentful/rich-text-types'
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { formatDate } from '../utils/utils'
import CloseButton from '../components/CloseButton'

const NewsBody = styled.div`
    background-color: white;
    position: fixed;
    width: 100%;
    height: calc(100% - 160px);
    z-index: 99999;
    bottom: ${p => p.open ? '0px' : 'calc(-100% + 80px)'};
    transition: bottom 0.3s ease-in-out;
    padding-bottom: 80px;
    overflow-x: scroll;
`

const InnerWrapper = styled.div`
    padding: 16px;
`

const StyledGatsbyImage = styled(GatsbyImage)`
    margin-top: 32px;
`

const Body = styled.div`
    font-family: Space Mono;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    margin-top: 12px;
    `

const StyledDate = styled.div`
    font-size: 14px;
    padding-left: 36px;
    margin-top: 4px;
`

const NewsBodyMobile = ({ news, newsOpen, setNewsOpen, open }) => {

    const options = {
        renderNode: {
            [INLINES.HYPERLINK]: (node, children) => {
                const uri = node.data.uri
                const _uri = uri.startsWith('www') ? ("http://" + uri) : uri
                return <a target="_blank" rel="noopener noreferrer" href={_uri}>{children}</a>
            }
        }
    }

    return (
        <NewsBody open={open}>

            {news &&
                <InnerWrapper>
                    <CloseButton
                        onClick={() => {
                            setNewsOpen(newsOpen.map(() => false))
                        }}
                    />
                    <Title
                        number={news?.number}
                        text={news?.title}
                    />
                    <StyledDate> {formatDate(new Date(news?.newsDate))}  </StyledDate>
                    <Body>
                        {news && documentToReactComponents(JSON.parse(news?.body.raw), options)}
                    </Body>
                    <StyledGatsbyImage
                        image={getImage(news?.image)}
                        alt={news?.title}
                    />
                </InnerWrapper>
            }

        </NewsBody>
    )
}

export default NewsBodyMobile
