import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import MainWrapperDesktop from "../components/MainWrapperDesktop";
import MainWrapperMobile from "../components/MainWrapperMobile";

const License = styled.div`
  z-index: 999999;
  position: absolute;
  top: 16px;
  width: 100%;
  right: auto;
  left: auto;
  text-transform: uppercase;
  text-align: center;
  mix-blend-mode: difference;
  color: white; 
`

const Main = styled.main`
  color: #000000;
  padding: 0;
  top: ${p => p.active ? '0px' : '-100vh'};
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 999;
  position: ${p => p.isMobile ? 'absolute' : 'relative'};
  visibility: ${p => p.visible ? 'visible' : 'hidden'};
  transition: all 0.4s ease-in-out;
`;

const Wrapper = styled.div`
  .desktop {
    display: initial;
  }
  .mobile {
    display: none;
  }

  @media (max-width: 800px) {
    .desktop {
      display: none;
    }
    .mobile {
      display: initial;
    }
  }
`;


const HomeComponent = ({ visible, isMobile, active, licenseNumber }) => {

  const data = useStaticQuery(graphql`
    query HomePageQuery {
      contentfulTloAboutPage {
        licenseNumber
      }
      allContentfulTloProject(sort: { fields: orderNumber, order: DESC }) {
        edges {
          node {
            title
            orderNumber
            location
            desktopImagesContainer {
              showOnHomePage 
              image {
                gatsbyImageData(
                  quality: 80,
                  width: 2100,
                  height: 1200
                )
              }
            }
            mobileImagesContainer {
              showOnHomePage 
              image {
                gatsbyImageData(
                  width: 1200,
                  height: 2100
                )
              }
            }
          }
        }
      }
    }
  `);

  const projects = data.allContentfulTloProject.edges;

  return (
    <Main visible={visible} isMobile={isMobile} active={active}>
      <Wrapper>
        <div className="desktop">
          <MainWrapperDesktop projects={projects} />
        </div>
        <div className="mobile">
          <License  >
            ©{new Date().getFullYear()}—{licenseNumber}
          </License>
          <MainWrapperMobile
            active={active}
            projects={projects.filter(project => project.node.imagesMobile !== null)}
          />
        </div>
      </Wrapper>
    </Main>
  )
};

export default HomeComponent;
