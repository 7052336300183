import React from "react"
import Label from "../wrapper_components/NewsLabelNumber"

const MenuButton = ({ onClick, value, text }) => {

  return (
    <button onClick={onClick} >
      <Label value={value} />
      <span> {text} </span>
    </button>

  )
}

export default MenuButton
