import React from "react";


const Close = () => {
  return (
    <>
      <svg width="46.114" height="43.114" viewBox="0 0 46.114 43.114">
        <g>
          <path
            fill="none"
            stroke="rgb(114,255,0)"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeWidth="3"
            d="M39.05693705 36.05695422L7.05695358 7.0569362"
          />
          <path
            fill="none"
            stroke="rgb(114,255,0)"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeWidth="3"
            d="M7.05695353 36.05695422L39.056937 7.0569362"
          />
        </g>
      </svg>
    </>
  );
};

export default Close;
