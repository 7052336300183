import React from 'react'
import styled from "styled-components";

const Row = styled.div`
  .mobile-screen {
    display: none;
  }

  code {
    font-size: inherit;
    font-family: inherit;
  };

  white-space: nowrap;
  display: grid;
  width: 1152px;
  margin: auto;
  position: relative;
  column-gap:32px;
  grid-template-columns: 334px 140px 704px;
  
  @media (max-width: 1200px) {
    column-gap: 20px;
    grid-template-columns: 0 124px 704px;
  }

  @media (max-width: 800px) {
    .regular {
        display: none;
    }

    .mobile-screen {
        display: block;
    }
  }

`;

const FooterCredits = ({ footerCreditsPhotos, footerCreditsDesign, footerCreditsCode, footerCreditsTypeface }) => {


    return (
        <Row>
            <div> </div>
            <div className='regular'>
                {footerCreditsPhotos && <h5> PORTRAIT PHOTOS: </h5>}
                {footerCreditsDesign && <h5> DESIGN: </h5>}
                {footerCreditsCode && <h5> CODE: </h5>}
                {footerCreditsTypeface && <h5> TYPEFACE: </h5>}
            </div>
            <div>
                {footerCreditsPhotos && <h5 style={{ display: 'flex' }}>
                    <span className='mobile-screen'> PORTRAIT PHOTOS: &nbsp; </span>
                    <a href={JSON.parse(footerCreditsPhotos.raw).content[0]?.content[1]?.data.uri}
                        rel="noopener noreferrer"
                        target="_blank">
                        {JSON.parse(footerCreditsPhotos.raw).content[0]?.content[1]?.content[0]?.value}
                    </a>
                </h5>}
                {footerCreditsDesign && <h5 style={{ display: 'flex' }}>
                    <span className='mobile-screen'> DESIGN: &nbsp; </span>
                    <a href={JSON.parse(footerCreditsDesign.raw).content[0]?.content[1]?.data.uri}
                        rel="noopener noreferrer"
                        target="_blank">
                        {JSON.parse(footerCreditsDesign.raw).content[0]?.content[1]?.content[0]?.value}
                    </a>
                </h5>}
                {footerCreditsCode && <h5 style={{ display: 'flex' }}>
                    <span className='mobile-screen'> CODE: &nbsp; </span>
                    <a href={JSON.parse(footerCreditsCode.raw).content[0]?.content[1]?.data.uri}
                        rel="noopener noreferrer"
                        target="_blank">
                        {JSON.parse(footerCreditsCode.raw).content[0]?.content[1]?.content[0]?.value}
                    </a>
                </h5>}
                {footerCreditsTypeface && <h5 style={{ display: 'flex' }}>
                    <span className='mobile-screen'> TYPEFACE: &nbsp; </span>
                    <a href={JSON.parse(footerCreditsTypeface.raw).content[0]?.content[1]?.data.uri}
                        rel="noopener noreferrer"
                        target="_blank">
                        {JSON.parse(footerCreditsTypeface.raw).content[0]?.content[1]?.content[0]?.value}
                    </a>
                </h5>}
            </div>
        </Row >
    )
}


export default FooterCredits