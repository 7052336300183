import React from "react";
import styled from "styled-components";

const StyledSpacer = styled.div`
  margin-bottom:  ${props => props.val + 'px'};
`;
const Spacer = ({ val }) => {
  return <StyledSpacer className='spacer' val={val} />;
};

export default Spacer;
