import React from "react"
import styled from "styled-components"
import Title from "./NewsTitle"
import Marquee from "react-fast-marquee"

const StyledNewsBar = styled.div`
    display: flex;
    align-items: center; 
    width: 100%;
    height: 55px;
    background-color: white;
    border-top: 2px solid black;
    border-bottom: 2px solid black;
    bottom: -2px;
    position: absolute;
    white-space: nowrap;
    z-index: 999999;
`

const NewsLabel = styled.span`
    background-color: white;
    padding: 10px 16px 10px 10px;
    z-index: 99;
`


const NewsBar = ({ newsSorted, newsOpen, setNewsOpen, zIndexes, setZIndexes, handleNewsOpen }) => {

    return (
        <StyledNewsBar>
            <NewsLabel> NEWS </NewsLabel>
            <Marquee
                style={{ position: "absolute" }}
                gradient={false}
                speed={20}
                direction='right'
            >
                {newsSorted.map(news => {
                    return <Title
                        as='button'
                        key={news.id}
                        number={news.number}
                        text={news.title}
                        onClick={() => {
                            setNewsOpen(handleNewsOpen(newsOpen, news))
                            const newZIndexes = zIndexes.map((elem, index) => {
                                if (news.number - 1 === index) {
                                    const max = Math.max.apply(Math, zIndexes)
                                    return (max === elem) ? elem : max + 1
                                } else {
                                    return elem
                                }
                            })
                            setZIndexes(newZIndexes)
                        }}
                    />
                })}
            </Marquee>
        </StyledNewsBar>
    )
}

export default NewsBar
