import React from "react";
import styled from "styled-components";

const StyledRow = styled.div`
  display: grid;
  column-gap: ${(p) => p.gutter};
  width: 1152px;
  margin: auto;
  grid-template-columns: ${(p) => p.cols};
  position: relative;

  padding-top: ${(p) => (p.withLine ? "32px" : 0)};
  border-top: ${(p) => (p.withLine ? "1px solid #000000" : "none")};
  border-bottom: ${(p) => (p.withLine && p.lastOne ? "1px solid #000000" : "none")};

  @media (max-width: 1200px) {
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    flex-direction: column;
    max-width: calc(100% - 40px);
  }
`;

const Row = ({ children, cols, gutter, withLine, lastOne }) => {
  return (
    <StyledRow
      cols={cols}
      gutter={gutter}
      withLine={withLine}
      lastOne={lastOne}
    >
      {children}
    </StyledRow>
  );
};

export default Row;
