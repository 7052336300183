import React, { useState, useEffect, useCallback, useRef } from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import styled from "styled-components";
import useWindowDimensions from '../utils/useWindowDimensions'

const isBrowser = () => typeof window !== "undefined";

const Project = styled.div`
  position: absolute;
  opacity: ${(props) => props.opacity};
  text-align: center;
  width: 100%;
`;

const ProjectTitle = styled.div`
  mix-blend-mode: difference;
  color: white;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-perspective: 1000px;
  perspective: 1000px;
  -webkit-backface-visibility: visible;
  backface-visibility: visible;
  position: absolute;
  z-index: 9999;
  width: 100%;
  text-align: center;
  top: ${p => p.offset}px;

  span {
    padding: 2px 6px;
  }
`;

const StyledGatsbyImage = styled(GatsbyImage)`
  height: ${p => p.height}px;

    picture {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: block;
      object-fit: cover;
      width: 100%;
      height: ${p => p.height}px;
    }
`;



const filteredImages = (project) => project.node.desktopImagesContainer?.filter(img => img?.showOnHomePage)

const MainWrapperDesktop = ({ projects }) => {
  const { width, height } = useWindowDimensions()

  const ref = useRef(null);
  const [cursorPosition, setCursorPosition] = useState({ x: 0 });
  const filteredProjects = projects.filter(project => project.node.desktopImagesContainer)

  useEffect(() => {
    const setFromEvent = (e) => setCursorPosition({ x: e.clientX });
    isBrowser() && window.addEventListener("mousemove", setFromEvent);
    return () => {
      isBrowser() && window.removeEventListener("mousemove", setFromEvent);
    };
  }, []);

  const getIndexesForEachNestedImage = useCallback(() => {
    const indexes = [];
    filteredProjects.map((project) => {
      return filteredImages(project).forEach(() =>
        indexes.push(indexes.length + 1)
      );
    });

    return filteredProjects.map((project) => {
      return filteredImages(project).map(() => {
        return indexes.shift();
      });
    });
  }, [filteredProjects]);

  const numberOfImages = filteredProjects
    .map((project) => {
      return filteredImages(project).length;
    })
    .reduce((a, b) => a + b, 0);
  const sectionWidth = width / numberOfImages;

  const chosenImage = () => {
    const imageNo = Math.ceil(cursorPosition.x / sectionWidth);
    if (cursorPosition.x >= width - 4) {
      return 0;
    }
    return imageNo || 0;
  };

  return (
    <div style={{ height: height - 56 }} >
      {filteredProjects.map((project, projectIndex) => {
        return (
          <section key={project.node.title}>
            {filteredImages(project).map((imagesContainer, imageIndex) => {
              const imageNumber = getIndexesForEachNestedImage()[projectIndex][imageIndex];
              return (
                <Project
                  key={imageIndex}
                  opacity={imageNumber === chosenImage() ? 1.0 : 0.0}
                >
                  <ProjectTitle offset={height - 112}>
                    <h3 >
                      {project.node.title} — {project.node.location}
                    </h3>
                  </ProjectTitle>
                  <div ref={ref}>
                    <StyledGatsbyImage
                      height={height - 56}
                      image={getImage(imagesContainer.image)}
                      alt={project.node.title}
                    />
                  </div>
                </Project>
              );
            })}
          </section>
        );
      })}
    </div >
  );
};

export default MainWrapperDesktop;
