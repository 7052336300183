import React, { useState, useEffect } from "react"
import styled from "styled-components"
import useWindowDimensions from '../utils/useWindowDimensions'
import { formatDate } from '../utils/utils'
import Title from "./NewsTitle";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { INLINES } from '@contentful/rich-text-types';
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import CloseButton from "../components/CloseButton";
import Draggable from 'react-draggable';

const randomNumberRange = (max, min) => Math.floor(Math.random() * (max - min + 1)) + min;

const StyledNewsBody = styled.div`
    position: fixed;
    z-index: ${p => p.zIndex + 9999999};
    font-size: 18px;
    background-color: #E8E8E8;
    width: ${p => p.newsBodyWidth}px;
    max-height: ${p => p.newsBodyHeight}px;
    top: ${p => p.positionY}px;
    left: ${p => p.positionX}px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
`

const NewsBackground = styled.div`
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: white;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 99;
`

const InnerWrapper = styled.div`
    padding: 29px 40px;
`

const ImageWrapper = styled.div`
    top: ${p => p.positiony}px;
    left: ${p => p.positionx}px;
    position: fixed;
    z-index: 10000000;
    width: ${p => p.imagewidth}px;
    height: ${p => p.imageheight}px;
`

const StyledGatsbyImage = styled(GatsbyImage)`
    background-color: #E8E8E8;
    width: 100%;
    height: 100%;
    pointer-events: none;
    position: relative;
`;


const Line = styled.hr`
    height: 1px;
    width: 100%;
    background: #000;
`

const DateBody = styled.div`
    font-family: Space Mono;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    display: flex;
    align-items: baseline;
    gap: 10px;
`

const StyledDate = styled.div`
    font-size: 14px;
    width: 28px;
    height: 28px;
    transform: translateY(40px) rotate(-90deg);
`

const newsBodyWidth = 480
const newsBodyHeight = 300
const imageWidth = 320
const imageHeight = 200

const NewsBody = ({ news, newsOpen, setNewsOpen, open, zIndexes, setZIndexes, handleNewsOpen }) => {
    const { width, height } = useWindowDimensions()
    const getNewsBodyPosition = [randomNumberRange(width - newsBodyWidth, 0), randomNumberRange(height - newsBodyHeight - 55, 0)]
    const [newsBodyPositionXY, setNewsBodyPositionXY] = useState(getNewsBodyPosition)
    const [imagePositionXY, setImagePositionXY] = useState()

    useEffect(() => {
        setNewsBodyPositionXY(getNewsBodyPosition)
    }, [open])

    useEffect(() => {
        const newsBodyX = newsBodyPositionXY[0]
        const newsBodyY = newsBodyPositionXY[1]
        const newsBodyRight = newsBodyWidth + newsBodyX
        const newsBodyBottom = newsBodyHeight + newsBodyY
        let posX = 0
        let posY = 0
        const randomBool = randomNumberRange(1, 0)

        // you can place image on the left side of the news-body
        if (newsBodyX >= imageWidth) {
            posX = randomNumberRange(newsBodyX - imageWidth, 0)
        }
        // you can place image on the right side of the news-body
        else if (width - newsBodyRight >= imageWidth) {
            posX = randomNumberRange(width - imageWidth, newsBodyRight)
        }
        // you can place image over the news-body
        if (newsBodyY >= imageHeight) {
            posY = randomNumberRange(newsBodyY - imageHeight - 55, 0)
        }
        // you can place image below the news-body
        else if (height - newsBodyBottom >= imageHeight) {
            posY = randomNumberRange(height - imageHeight - 55, newsBodyBottom)
        }

        randomBool ? setImagePositionXY([randomNumberRange(width - imageWidth, 0), posY])
            : setImagePositionXY([posX, randomNumberRange(height - imageHeight - 55, 0)])

    }, [newsBodyPositionXY])

    const options = {
        renderNode: {
            [INLINES.HYPERLINK]: (node, children) => {
                const uri = node.data.uri
                const _uri = uri.startsWith('www') ? ("http://" + uri) : uri
                return <a target="_blank" rel="noopener noreferrer" href={_uri}>{children}</a>
            }
        }
    }

    return (
        <>


            {imagePositionXY &&
                <Draggable>
                    <ImageWrapper
                        positionx={imagePositionXY[0]}
                        positiony={imagePositionXY[1]}
                        imagewidth={imageWidth}
                        imageheight={imageHeight}
                    >
                        <StyledGatsbyImage
                            image={getImage(news.image)}
                            alt={news.title}
                        />
                    </ImageWrapper>
                </Draggable>
            }

            <Draggable
                onStart={() => {
                    setNewsOpen(handleNewsOpen(newsOpen, news))
                    const newZIndexes = zIndexes.map((elem, index) => {
                        if (news.number - 1 === index) {
                            const max = Math.max.apply(Math, zIndexes)
                            return (max === elem) ? elem : max + 1
                        } else {
                            return elem
                        }
                    })
                    setZIndexes(newZIndexes)
                }}
            >
                <StyledNewsBody
                    newsBodyWidth={newsBodyWidth}
                    newsBodyHeight={newsBodyHeight}
                    positionX={newsBodyPositionXY[0]}
                    positionY={newsBodyPositionXY[1]}
                    zIndex={zIndexes[news.number - 1]}
                >
                    <InnerWrapper>
                        <CloseButton
                            onClick={() => {
                                const newState = newsOpen.map((elem, index) => (news.number - 1 === index) ? false : elem)
                                setNewsOpen(newState)
                            }}
                        />
                        <Title
                            number={news.number}
                            text={news.title}
                        />
                        <Line />
                        <DateBody>
                            <StyledDate> <span> {formatDate(new Date(news.newsDate))} </span> </StyledDate>
                            <div>
                                <>{documentToReactComponents(JSON.parse(news.body.raw), options)}</>
                            </div>
                        </DateBody>
                    </InnerWrapper>
                </StyledNewsBody>
            </Draggable>

            <NewsBackground />

        </>
    )
}

export default NewsBody
